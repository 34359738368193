import StorageService from 'services/storage.services';
import mammoth from 'mammoth';
export const Truncate = (str, limit = 110) => {
    const length = limit;
    const ending = '...';

    if (!str) return "";
    if (str.length > length) return str.substring(0, length - ending.length) + ending;
    else return str;
}

export const getVideoId = (url) => {
    let video_id = url.split('v=')[1];
    const ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
        return video_id;
    }
    return video_id;
}

export const checkValidPermission = (permission) => {
    const userRole = StorageService.getUserRole();
    if (!userRole) {
        return false
    }
    if (typeof (permission) === 'string') {
        if (permission === userRole) {
            return true;
        }
    }
    if (Array.isArray(permission)) {
        if (permission.includes(userRole)) {
            return true;
        }
    }
}

export const GetHTMLfromBuffer = async (arrayBuffer) => {
    let docResult = "";
    docResult = await mammoth.convertToHtml({ arrayBuffer }, {})
    // .then((docContent) => { docResult = docContent; })
    // .catch((error) => { throw error; })
    // .done();
    return docResult;
}

export const trimWhiteSpaces = (str)=> str.replace(/ +/g, "");
