import React from 'react';
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { Provider } from "react-redux";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

const StoreProvider = (props) => {
    return <Provider store={store}>
        {props.children}
    </Provider>

}

export default StoreProvider;