import { combineReducers } from "redux";

import search from './modules/search';
import tags from './modules/tags';
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
    search,
    tags,
    form: formReducer
});

export default rootReducer;