import axios from "axios";
import { apiUrl, authApiURL, defaultHeaders } from "config";
import StorageService from "services/storage.services";

const DeleteCatalog = async (catelogueId) => {
  return await fetch(`${authApiURL}/deletecatalogue`, {
    method: "POST",
    body: JSON.stringify({ catelogueId }),
    headers: {
      ...defaultHeaders,
      "Ocp-Apim-Subscription-Key": StorageService.getUserData().api_key,
    },
  });
};
const GetCatalogList = async (type, value = null) => {
  let url = `${apiUrl}/catalogcategory?type=${type}`;
  if (value != null) {
    url = `${apiUrl}/catalogcategory?type=${type}&value=${value}`;
  }
  return await fetch(url, {
    method: "GET",
    headers: defaultHeaders,
  });
};

const GetCatalogPageData = async () => {
  try {
    return await fetch(`${apiUrl}/cataloguepage?limit=6`, {
      headers: defaultHeaders,
    });
  } catch (error) {
    throw error;
  }
};
const fetchUserProfile = async (user_id) => {
  try {
    return await axios.get(`${apiUrl}/userprofile?user_id=${user_id}`, {
      headers: {
        ...defaultHeaders,
      },
    });
  } catch (error) {
    if (error.response) {
      return;
      // return notify.show(error.response.data, "error", 3500);
    }
    throw error;
  }
};

const GetLogin = (location) => {
  window.location.href = `${authApiURL}/login/auth?state=${location}`;
};

const GetNotificationData = async (userId) => {
  const userData = StorageService.getUserData();
  try {
    return await axios.get(
      `${apiUrl}/notifications?user=${userData.user_email}`,
      {
        headers: {
          ...defaultHeaders,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const RegisterDeviceForNotification = async (deviceId) => {
  const userData = StorageService.getUserData();
  try {
    return await axios.post(
      `${apiUrl}/registerdevice`,
      {
        user_email: userData.user_email,
        device_token: deviceId,
      },
      {
        headers: {
          ...defaultHeaders,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const GetNavMasterData = async () => {
  try {
    return await fetch(`${apiUrl}/mainmenu`, {
      headers: defaultHeaders,
    });
  } catch (error) {
    throw error;
  }
};

export {
  DeleteCatalog,
  GetCatalogPageData,
  GetCatalogList,
  fetchUserProfile,
  GetLogin,
  GetNotificationData,
  RegisterDeviceForNotification,
  GetNavMasterData,
};
