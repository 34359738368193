import React from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { useNotificationContext } from '../Notifications/notification.hooks';
import { useMyBlogContext } from 'views/blog/my-blog/myBlog.hooks';


const Index = ({ isLoggedIn }) => {
    const { initFirebase, fetchNotifications } = useNotificationContext();
    const { fetchMyBlogs } = useMyBlogContext();
    const [show, letShow] = React.useState(false);

    React.useEffect(() => {
        letShow(isLoggedIn)
    }, [isLoggedIn])

    const getVisibility = () => {
        return !show || Notification.permission === "granted" || Notification.permission === "denied"
    }
    const initFunctionForNotificationCallback = () => {
        fetchNotifications();
        fetchMyBlogs();
    }


    const baseClasses = classnames('card card-subscribe d-flex flex-row shadow', {
        'hide-card-subscribe': !show
    })

    if (getVisibility()) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="card-subscribe-overlay">
            </div>
            <section className={baseClasses}>

                <div>
                    <svg width="50" viewBox="0 0 512 512" ><circle cx="256" cy="256" r="256" fill="#00abe9" /><path d="M506.079 310.899L285.18 90 219 308l-112.789 75.094 127.971 127.972A259.79 259.79 0 0 0 256 512c122.538 0 224.948-86.102 250.079-201.101z" fill="#0080af" /><path d="M226.82,90v62.185h58.359V90H226.82z" fill="#5a5a5a" /><path d="M256 90h29.18v62.19H256z" fill="#444" /><path d="M207.367 363.641v9.727c0 3.333.337 6.582.973 9.727C212.853 405.283 232.488 422 256 422c6.484 0 12.677-1.271 18.331-3.586 14.862-6.05 26.061-19.252 29.329-35.32.635-3.145.973-6.393.973-9.727v-9.727l-97.266.001zm150.178-58.36h-.389l-16.341 19.453h-172.16l-13.812-19.453h-.389c9.143-22.371 14.006-45.909 14.006-70.226v-14.006c0-38.803 23.713-72.995 58.359-85.652a83.1 83.1 0 0 1 7.198-2.276c4.28-1.167 8.948-2.14 13.423-2.529 2.918-.195 5.836-.389 8.559-.389a83.18 83.18 0 0 1 21.982 2.918 84.76 84.76 0 0 1 7.198 2.153c34.646 12.022 58.359 44.943 58.359 82.468v17.313c.001 24.317 4.864 47.855 14.007 70.226z" fill="#fdbf00" /><g fill="#ff9f00"><path d="M405.789 383.094H303.66c-3.268 16.068-14.467 29.271-29.329 35.32L256 383.094V130.203a83.18 83.18 0 0 1 21.982 2.918 84.76 84.76 0 0 1 7.198 2.153c34.646 12.022 58.359 44.943 58.359 82.468v17.313c0 24.316 4.863 47.855 14.006 70.226h-.389l2.723 5.836c4.085 9.337 9.532 19.064 16.146 28.791l29.764 43.186z" /><path d="M304.633 363.641v9.727c0 3.333-.337 6.582-.973 9.727-3.268 16.068-14.467 29.271-29.329 35.32C268.677 420.729 262.484 422 256 422v-58.359h48.633z" /></g><path d="M376.026 339.908c-6.614-9.727-12.061-19.453-16.146-28.791l-2.723-5.836H154.844l-2.723 5.836c-4.085 9.337-9.532 19.064-16.146 28.791l-29.763 43.186H405.79l-29.764-43.186z" fill="#ffd400" /><path d="M405.789 383.094H256v-77.813h101.156l2.723 5.836c4.085 9.337 9.532 19.064 16.146 28.791l29.764 43.186z" fill="#fdbf00" /></svg>
                </div>
                <div className="pl-3">
                    <div className="mb-3">Subscribe to notifications to stay up to date</div>
                    <div>
                        <Button onClick={() => { letShow(false); initFirebase(initFunctionForNotificationCallback); }} color="primary" size="sm" className="text-uppercase">Subscribe</Button>
                        <Button onClick={() => { letShow(false) }} size="sm" className="text-uppercase ml-3">Not Now</Button>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Index;
