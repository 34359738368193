import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Button, Form, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { actions } from 'redux/reducers/modules/tags';
import "./styles.scss";
import { DEFAULT_LAYOUT_PATH } from 'routes';

class Search extends React.Component {
    constructor() {
        super();
        this.inputRef = React.createRef();
    }
    componentDidMount () {
        this.props.dispatch(actions.letFetchTags());
    }


    letSearch = () => {
        if (!this.inputRef.current.value) {
            return;
        }
        let seachQuery = encodeURIComponent(this.inputRef.current.value)
        this.props.history.push(`${DEFAULT_LAYOUT_PATH}/search/${seachQuery}`);
        this.inputRef.current.value = "";
    }
    onsubmit = (e) => {
        e.preventDefault();
        this.letSearch();
    }
    render () {
        return (
            <Form onSubmit={this.onsubmit} className={this.props.className} >
                <InputGroup size="md">
                    <Input type="text" innerRef={this.inputRef} placeholder="Search" className="searchPanelInput border-0" />
                    <InputGroupAddon addonType="append">
                        <Button onClick={this.letSearch} type="button" color="primary"><FontAwesomeIcon icon={faSearch} /></Button>
                    </InputGroupAddon>
                </InputGroup>
            </Form>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        tags: state.tags.dataList.tags
    }
}
export default connect(mapStateToProps)(withRouter(Search))