import React from 'react';
import { checkValidPermission } from 'utilities/utils';
import StorageService from 'services/storage.services';
class Authorization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: false
        }
    }
    componentDidMount() {
        const { permit, checkWithEmail } = this.props;
        if (permit) {
            this.setState({ isValid: checkValidPermission(permit) })
        }
        if (checkWithEmail) {
            const userData = StorageService.getUserData();
            if (userData) {
                this.setState({ isValid: userData.user_email === checkWithEmail ? true : false })
            } else {
                this.setState({ isValid: false })
            }
        }
    }
    render() {
        const { isValid } = this.state;
        return (
            <React.Fragment>
                {isValid && this.props.children}
                {!isValid && null}
            </React.Fragment>
        )
    }
}
export default Authorization;