import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ShowIf, Spinner } from 'components';
import { roles } from 'config';
import React from 'react';
import { notify } from 'react-notify-toast';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardText, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody } from 'reactstrap';
import { DeleteCatalog } from 'services/httpServices';
import StorageService from 'services/storage.services';
import { Truncate } from 'utilities/utils';
import { DEFAULT_LAYOUT_PATH } from 'routes';

const Tile = ({ dataSrc, className, ...rest }) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [removing, setRemoving] = React.useState(false);
    const [catalogTobeRemoved, setCatalogTobeRemoved] = React.useState(null);

    const history = useHistory();
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const rootClasses = classNames(`d-flex shadow catalog-card ${className}`);
    const FooterClasses = classNames('d-flex border-0 justify-content-center pb-3');

    const confirmationModal = (id) => {
        setModal(!modal);
        setCatalogTobeRemoved(id);
    };

    const letDeleteTile = async () => {
        setRemoving(true);
        const response = await DeleteCatalog(catalogTobeRemoved);
        if (response.status === 400) {
            notify.show("Bad Request", 'error');
            return;
        }
        if (response.status === 404) {
            notify.show("Not Found", 'error');
            return;
        }


        if (response.status === 200) {
            notify.show("Catalog removed successfully", 'success');
            confirmationModal(null);
            setRemoving(false);
            if (history.location.pathname === `${DEFAULT_LAYOUT_PATH}/addCatalog`) {
                if (history.location.state.data.catalogId === catalogTobeRemoved) {
                    history.push(`${DEFAULT_LAYOUT_PATH}/catalogs`);
                    window.location.reload();
                    return;
                }
            } else {
                window.location.reload();
            }
            return;
        }
    }
    const cancelRemove = () => {
        confirmationModal(null);
    }
    const thumbnailname = dataSrc.thumbnailUrl.includes('.png') ? dataSrc.thumbnailUrl : `${dataSrc.thumbnailUrl}.png`;
    return (
        <React.Fragment>
            <Card className={rootClasses} {...rest}>
                <Link
                    to={{ pathname: `${DEFAULT_LAYOUT_PATH}/details/${dataSrc.catalogId}`, state: { data: dataSrc } }}
                    style={{ textDecoration: 'none' }}
                    className="flex-grow-1">

                    <div className="position-relative overflow-hidden tile-thumb">
                        {
                            StorageService.getUserData() &&
                            ((dataSrc.userId === StorageService.getUserData().user_email && StorageService.getUserRole()) || StorageService.getUserRole() === roles.admin)
                            &&
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle tag="div" className="tile-action" onClick={(e) => { e.preventDefault() }}>
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={(e) => { e.preventDefault(); history.push(`${DEFAULT_LAYOUT_PATH}/addCatalog?editMode=true&id=${dataSrc.catalogId}`, { data: dataSrc }) }} className="small">Edit</DropdownItem>
                                    <DropdownItem onClick={(e) => { e.preventDefault(); confirmationModal(dataSrc.catalogId) }} className="small">Delete</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                        }
                        {dataSrc.thumbnailUrl
                            && <img src={require(`assets/images/${thumbnailname}`)} className="card-img-top card-thumb" alt="" />
                        }
                        {!dataSrc.thumbnailUrl
                            && <img src={require('assets/images/img5.png')} className="card-img-top card-thumb" alt="" />
                        }
                    </div>
                    <CardBody>
                        <CardTitle tag="h6">
                            <div className="text-truncate">{dataSrc.catalogName}</div>
                        </CardTitle>
                        <CardText style={{ fontSize: 12 }}>
                            {Truncate(dataSrc.summary)}
                        </CardText>
                    </CardBody>
                </Link>
                {
                    StorageService.getUserData() &&
                    (dataSrc.type === 'Accelerators' || dataSrc.type === 'Accelerator') &&
                    (dataSrc.userId === StorageService.getUserData().user_email || StorageService.getUserRole() === roles.admin)
                    &&

                    <CardFooter className={FooterClasses}>
                        <a target="_blank" rel="noopener noreferrer" href={dataSrc.acceleratorUrl} className="playBtn btn btn-primary">
                            <span className="sr-only">{dataSrc.catalogName} Accelerator</span>
                        </a>
                    </CardFooter>

                }
            </Card>
            <Modal isOpen={modal} toggle={confirmationModal} backdrop="static">
                <ModalBody className="py-5 text-center">
                    <ShowIf condition={removing}>
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            background: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}><Spinner isLoading={removing} size="sm" /></div>
                    </ShowIf>
                    Do you want to delete the Catalog?
                    <div className="mt-3">
                        <Button color="danger" onClick={letDeleteTile} size="sm" className="mx-2 px-4">Yes</Button>
                        <Button color="primary" onClick={cancelRemove} size="sm" className="mx-2 px-4">Cancel</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Tile;