import React from 'react';
import { no_data_found } from 'assets/images';
import classnames from 'classnames';

const index = ({ shouldshow, children, size, className, ...rest }) => {
    const classes = classnames(
        'd-flex justify-content-center w-100 align-items-center text-center flex-column',
        className
    )
    const placeholderTemp = <div
        className={classes}
        style={{ height: '100%' }}
        {...rest}
    ><div>
            <img src={no_data_found} alt="" style={{
                width: size === 'sm' ? 60 : 140,
                marginBottom: 25
            }} />
        </div>
        <div className="mb-2">No Data Found</div>
        {children}
    </div>
    return shouldshow ? placeholderTemp : null
};

export default index;