/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container } from 'reactstrap';


const Footer = () => {

    return (
        <footer className="site-footer">
            <Container>
                {['FAQ', 'Privacy and Cookies', 'Terms of Use'].map(row => {
                    return <a href="" key={row} className="px-2">
                        {row}
                    </a>
                })}
                &copy; YASH
            </Container>
        </footer>
    );
};

export default Footer;