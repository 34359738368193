import React from 'react';
import YouTube from 'react-youtube';
import { Media } from 'reactstrap';
const opts = {
    height: 200,
    playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        allowtransparency: true,
        showinfo: 0,
        rel: 0,
        egm: 0,
        iv_load_policy: 1,
        enablejsapi: 1
    }
};

class VideoGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentVideo: null,
            title: 'Big Data Tutorial For Beginners',
            videoMounted: false
        }
    }
    static ytEvent = null;

    componentDidMount() {
        this.setState({
            currentVideo: Array.isArray(this.props.playlist) ? this.props.playlist[0] : this.props.playlist
        })
    }

    _onReady = (event) => {
        event.target.pauseVideo();
        VideoGallery.ytEvent = event;
        this.setState({
            videoMounted: true
        })
    }
    _onPlay = () => {
        VideoGallery.ytEvent.target.playVideo();
    }
    SetVideo = (e, vid) => {
        if (this.state.videoMounted) {
            this.setState({
                currentVideo: vid
            }, () => { this._onPlay() })
        }
    }

    render() {
        const { currentVideo } = this.state;
        const { playlist } = this.props;

        return (
            <div className="playlist-media-container " style={{ boxSizing: 'content-box' }}>
                <div className="palyer-wrapper">
                    <YouTube
                        id="video"
                        className="w-100"
                        videoId={currentVideo}
                        opts={opts}
                        onReady={this._onReady}
                    /></div>
                <div className="media-group">
                    {
                        Array.isArray(playlist) &&
                        playlist.map(row => {
                            return <Media key={row} className="playlist-media align-items-center" onClick={(e) => { this.SetVideo(e, row) }}>
                                <Media left>
                                    <Media object src={`https://img.youtube.com/vi/${row}/1.jpg`} className="mr-3" style={{ width: 80 }} alt="Big Data Tutorial For Beginners" />
                                </Media>
                                <Media body className="text-truncate">
                                    <Media heading tag="h6" className="text-truncate">
                                        Big Data Tutorial For Beginners
                                    </Media>
                                </Media>
                            </Media>
                        })
                    }
                    {
                        !Array.isArray(playlist) &&
                        <Media className="playlist-media align-items-center" onClick={(e) => { this.SetVideo(e, playlist) }}>
                            <Media left>
                                <Media object src={`https://img.youtube.com/vi/${playlist}/1.jpg`} className="mr-3" style={{ width: 80 }} alt="Big Data Tutorial For Beginners" />
                            </Media>
                            <Media body className="text-truncate">
                                {/*<Media heading tag="h6" className="text-truncate">
                                    Big Data Tutorial For Beginners
                    </Media>*/}
                            </Media>
                        </Media>
                    }


                </div>
            </div>
        )
    }
}

export default VideoGallery;