import { lazy } from "react";

const Home = lazy(() => import("views/home"));
const Catalog = lazy(() => import("views/catalog"));
const ProjectDetail = lazy(() => import("views/projectDetails"));
const AddCatalog = lazy(() => import("views/addCatalog"));
const CatalogList = lazy(() => import("views/catalogList"));
const SearchView = lazy(() => import("views/search"));
const BlogList = lazy(() => import("views/blog/blog-list"));
const AddBlog = lazy(() => import("views/blog/add-blog"));
const BlogDetails = lazy(() => import("views/blog/details"));
const MyBlogs = lazy(() => import("views/blog/my-blog"));
const AddDomain = lazy(() => import("views/addDomain"));

export {
  Home,
  Catalog,
  ProjectDetail,
  AddCatalog,
  CatalogList,
  SearchView,
  BlogList,
  AddBlog,
  BlogDetails,
  MyBlogs,
  AddDomain,
};
