import React from 'react';

const index = (props) => {
    let width = props.size === "sm" ? 50 : 100;
    if (props.isLoading) {
        return (
            <div
                className="d-flex flex-column justify-content-center w-100 align-items-center"
                style={{ height: '100%' }}
            >
                <svg style={{ width: width }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="rotate(0)"><circle cx="73.801" cy="68.263" fill="#e15b64" r="3" transform="rotate(358.39 50.0002 50.0002)">
                        <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50" times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.4925373134328357s" begin="0s"></animateTransform>
                    </circle><circle cx="68.263" cy="73.801" fill="#f47e60" r="3" transform="rotate(359.892 50.0148 50.0148)">
                            <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50" times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.4925373134328357s" begin="-0.062s"></animateTransform>
                        </circle><circle cx="61.481" cy="77.716" fill="#f8b26a" r="3" transform="rotate(0.366543 50 50)">
                            <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50" times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.4925373134328357s" begin="-0.125s"></animateTransform>
                        </circle><circle cx="53.916" cy="79.743" fill="#abbd81" r="3" transform="rotate(2.42193 50 50)">
                            <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50" times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.4925373134328357s" begin="-0.187s"></animateTransform>
                        </circle><circle cx="46.084" cy="79.743" fill="#849b87" r="3" transform="rotate(6.56017 50 50)">
                            <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50" times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.4925373134328357s" begin="-0.25s"></animateTransform>
                        </circle><circle cx="38.519" cy="77.716" fill="#6492ac" r="3" transform="rotate(12.8961 50 50)">
                            <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50" times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.4925373134328357s" begin="-0.312s"></animateTransform>
                        </circle><circle cx="31.737" cy="73.801" fill="#637cb5" r="3" transform="rotate(21.9125 50 50)">
                            <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50" times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.4925373134328357s" begin="-0.375s"></animateTransform>
                        </circle><circle cx="26.199" cy="68.263" fill="#6a63b6" r="3" transform="rotate(33.612 50 50)">
                            <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50" times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.4925373134328357s" begin="-0.437s"></animateTransform>
                        </circle><animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;0 50 50" times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.4925373134328357s"></animateTransform></g>
                </svg>
                <span>{props.infoText}</span>
            </div>
        );
    } else {
        return null;
    }
};

export default index;