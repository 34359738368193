import { Spinner } from 'components';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Button, Modal } from 'reactstrap';
import { getCroppedImg } from 'utilities/cropImage';

const ImageCropper = (props) => {
    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    // const [croppedImage, setCroppedImage] = useState(null)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [imageIsLoading, setImageisLoading] = useState(false);
    const [isCropping, setIsCropping] = useState(false);

    const readFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }
    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = React.useCallback(async () => {
        setIsCropping(true);
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels
            )
            // setCroppedImage(croppedImage);
            props.afterCropping(croppedImage);
            setIsCropping(false);
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, imageSrc, props])

    const getImageFile = React.useCallback(async () => {
        let imageDataUrl = await readFile(props.file);
        setImageSrc(imageDataUrl);
        setTimeout(() => {
            setImageisLoading(false)
        }, 500);
    }, [props.file]); 



    return (
        <Modal isOpen={props.modal} toggle={props.toggle} size="cropper" onOpened={getImageFile} onEnter={() => { setImageisLoading(true) }} onClosed={() => { setImageisLoading(false); setImageSrc(null) }}>
            <div className="position-relative h-100 w-100">
                <Spinner isLoading={imageIsLoading} infoText="Please wait..." />
                {!imageIsLoading &&
                    <Cropper
                        onCropComplete={onCropComplete}
                        zoom={1}
                        image={imageSrc}
                        cropSize={{ width: 1200, height: 400, ...props.cropSize }}
                        crop={crop}
                        onCropChange={setCrop}
                    />
                }
            </div>
            <div className="text-center p-4">
                <Button onClick={showCroppedImage} disabled={isCropping} className="px-5" color='primary'>
                    {isCropping && 'Cropping...'}
                    {!isCropping && 'Crop'}
                </Button>
                <Button className="px-5 ml-3" onClick={props.toggle}>
                    Cancel
                </Button>
            </div>
        </Modal>

    );
}

export default ImageCropper;
