import React from "react";
import NotificationContext from "./notification.context";
import { GetNotificationData } from "services/httpServices";
import FirebasePushNotifications from "services/push-notification";

const useNotificationContextValues = () => {
  const [notificationList, setNotificationList] = React.useState(null);
  const [count, setCount] = React.useState(null);
  const [isDataAvailable, setIsDataAvailable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchNotifications = React.useCallback(async () => {
    setIsLoading(true);
    try {
      let { data } = await GetNotificationData();
      setCount(setCountToState(data.blogs.length));
      if (data.blogs.length) {
        setNotificationList(data.blogs);
        setIsLoading(false);
        setIsDataAvailable(true);
      }
    } catch (error) {
      throw error;
    }
  }, [setNotificationList]);

  const clearAlert = React.useCallback(() => {
    setCount(null);
  }, []);

  const setCountToState = (count) => {
    return count < 10 && count > 0 ? `0${count}` : parseInt(count);
  };

  const initFirebase = FirebasePushNotifications.initializeFirebase;

  const removeNotification = (id) => {
    let newNotificationList = notificationList.filter((row) => {
      return row.blogId !== id;
    });
    setNotificationList(newNotificationList);
    setCount(newNotificationList.length);
    if (!newNotificationList.length) {
      setIsDataAvailable(false);
      setCount(null);
    }
  };

  return {
    notificationList,
    count,
    isLoading,
    isDataAvailable,
    fetchNotifications,
    clearAlert,
    initFirebase,
    removeNotification,
  };
};

const useNotificationContext = () => {
  const notificationContext = React.useContext(NotificationContext);
  if (!notificationContext) {
    throw new Error(
      "NotificationContext must be used within the NotificationContext.Provider"
    );
  }
  return notificationContext;
};

export { useNotificationContext, useNotificationContextValues };
