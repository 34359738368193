import { apiUrl, defaultHeaders } from 'config';
export const constants = {
    LET_SEARCH: 'LET_SEARCH',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS'
}

export const actions = {
    letSearch(tag) {
        return async dispatch => {
            try {
                dispatch({ type: constants.LET_SEARCH });
                const response = await fetch(`${apiUrl}/cataloguesearch?searchString=${tag}`, {
                    method: 'GET',
                    headers: defaultHeaders
                });
                if (response) {
                    const result = await response.json();
                    dispatch(actions.searchSuccess(result));
                }
            } catch (error) {
                throw error;
            }
        }
    },
    searchSuccess(result) {
        return { type: constants.SEARCH_SUCCESS, payload: result }
    }
}

const initialState = {
    isloading: false,
    searchedCatalogs: [],
    searchedBlogs: []
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.LET_SEARCH:
            return {
                ...state,
                isloading: true
            };
        case constants.SEARCH_SUCCESS:
            return {
                ...state,
                isloading: false,
                searchedCatalogs: action.payload.catalogues,
                searchedBlogs: action.payload.blogs
            };
        default:
            return state;
    }
}