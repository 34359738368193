import firebase from "firebase";
import { RegisterDeviceForNotification } from 'services/httpServices';
import { notify } from "react-notify-toast";



class FirebasePushNotifications {
    static firebaseObject = null;
    static initializeFirebase = (cb) => {
        firebase.initializeApp({
            apiKey: "AIzaSyAT8FW3tTc51SSWd2w3mc2IjYuMMdOWimk",
            authDomain: "yash-digital-catalogue.firebaseapp.com",
            databaseURL: "https://yash-digital-catalogue.firebaseio.com",
            projectId: "yash-digital-catalogue",
            storageBucket: "yash-digital-catalogue.appspot.com",
            messagingSenderId: "419398864775",
            appId: "1:419398864775:web:1cfee470f766619ec84d22",
            measurementId: "G-NEL65P4F3P"
        });
        this.firebaseObject = firebase.messaging();
       
        this.getPermissionToken()
        this.initServices(cb);
    };
    static getPermissionToken = async () => {
        try {
            const messaging = this.firebaseObject;
            await messaging.requestPermission();

            const token = await messaging.getToken();
            const isDeviceAdded = await RegisterDeviceForNotification(token);
            if (isDeviceAdded.status === 200) {
                notify.show(isDeviceAdded.data, "success", 3500);
            }
            return token;
        } catch (error) {
            notify.show('Something went wrong', "error", 3500);
            console.error('error-->', error);
        }
    };

    static initServices = (cb) => {
        const messaging = this.firebaseObject;
        messaging.onMessage(payload => {
            const { notification } = payload;
            if (typeof cb === 'function') {
                setTimeout(() => { cb() }, 800)
            };
            notify.show(notification.body, 'success');
        });

    };
}

export default FirebasePushNotifications;
