const protocol = "https";
const appUrl = process.env.REACT_APP_BACKEND_URL;
  
// const appUrl =
  // process.env.REACT_APP_ENV === "production"
  //   ? "yash-digital-catalogue-prod.azure-api.net"
  //   : // ? "yash-digital-catalogue.azure-api.net"
  //     // : "yash-digital-catalogue-dev.azure-api.net";
  //     "yashydc-dev.azure-api.net";

// base path for the all requests.
const apiUrl = `${protocol}://${appUrl}/users`;
// base path for the authorized resources
const authApiURL = `${protocol}://${appUrl}`;
// console.log(process.env.REACT_APP_ENV)

const localUserDataKey = "digitalCatalogUser";

const chatbotUrl =
  "https://chatbot-tokenproviderapp-ydc.azurewebsites.net/api/DirectLine";

const roles = {
  admin: "admin", //Super admin can perform all the CURD oprations
  owner: "owner", //for Specific catalog
  public: "public", //no access
};

const statusList = {
  accepted: "approved",
  pending: "pending",
  rejected: "rejected",
  my_blogs: "my_blogs",
};

const defaultHeaders = {
  "Content-Type": "application/json",
  "Ocp-Apim-Trace": true,
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_FIXED_KEY,
};

export {
  localUserDataKey,
  apiUrl,
  roles,
  defaultHeaders,
  authApiURL,
  chatbotUrl,
  statusList,
};
