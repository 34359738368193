import { Icon } from 'components';
import { ThemeContext } from 'context';
import React from 'react';
import { Button } from 'reactstrap';
import classname from 'classnames';

const ThemedButton = (props) => {
    const context = React.useContext(ThemeContext);
    const onChangeHandler = () => {
        context.toggleTheme();
    }
    const rootClasses = classname('themeBtn', [props.className])
    return (
        <Button onClick={onChangeHandler} color="primary" className={rootClasses}>
            <Icon iconName={context.theme === 'light' ? 'moon' : 'sun'} />
        </Button>
    );

}

export default ThemedButton;