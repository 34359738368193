import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  tag: PropTypes.any,
};

const defaultProps = {
  tag: React.Fragment,
};

const ShowIf = (props) => {
  const { children, condition, tag, key } = props;

  return condition ? React.createElement(tag, { key }, children) : null;
};

ShowIf.defaultProps = defaultProps;
ShowIf.propTypes = propTypes;

export default ShowIf;
