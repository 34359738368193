import React, { Suspense } from "react";
import SetRoutes, { CoreLayoutComponents, DefaultLayoutComponents } from "routes";
import { Redirect, Route, Switch } from "react-router-dom";
import { CoreLayout, DefaultLayout } from "layout";
import { PageNotFound } from "components";

const RouteSwitcher = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route exact path="/home">
                    <CoreLayout>
                        <Switch>
                            {CoreLayoutComponents.map((route, i) => (
                                <SetRoutes key={i} {...route} />
                            ))}
                        </Switch>
                    </CoreLayout>
                </Route>
                <Route path="/dashboard">
                    <DefaultLayout>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                {DefaultLayoutComponents.map((route, i) => (
                                    <SetRoutes key={i} {...route} />
                                ))}
                                <Route path="*" render={() => <Redirect to="/pagenotfound" />} />
                            </Switch>
                        </Suspense>
                    </DefaultLayout>
                </Route>
                <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/home" />}
                />
                <Route path="/pagenotfound" component={PageNotFound} />
                <Route path="*" render={() => <Redirect to="/pagenotfound" />} />
            </Switch>
        </Suspense>
    );
};

export default RouteSwitcher;