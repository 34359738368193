import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShowIf, Spinner } from 'components';
import { roles, statusList } from 'config';
import dateFormat from 'dateformat';
import React from 'react';
import { notify } from 'react-notify-toast';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody } from 'reactstrap';
import { DEFAULT_LAYOUT_PATH } from 'routes';
import BlogServices from 'services/blog.service';
import StorageService from 'services/storage.services';
import { Truncate } from 'utilities/utils';
import { blog_thumb_placeholder } from 'assets/images';

const Index = ({ blog, forapproval }) => {
    const history = useHistory();
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [removing, setRemoving] = React.useState(false);
    const [blogTobeRemoved, setBlogTobeRemoved] = React.useState(null);
    const confirmationModal = (id) => {
        setModal(!modal);
        setBlogTobeRemoved(id);
    };

    const letDeleteTile = async () => {
        setRemoving(true);
        const response = await BlogServices.deleteBlog(blogTobeRemoved);
        if (response.status === 400) {
            notify.show("Bad Request", 'error');
            return;
        }
        if (response.status === 404) {
            notify.show("Not Found", 'error');
            return;
        }


        if (response.status === 200 && response.data === 'Blog Deleted') {
            notify.show("Blog removed successfully", 'success');
            confirmationModal(null);
            setRemoving(false);
            setTimeout(() => {
                if (history.location.pathname === `${DEFAULT_LAYOUT_PATH}/add-blog`) {
                    if (history.location.state.data.catalogId === blogTobeRemoved) {
                        history.push(`${DEFAULT_LAYOUT_PATH}/catalogs`);
                        window.location.reload();
                        return;
                    }
                } else {
                    window.location.reload();
                }
            }, 500)
            return;
        }
    }
    const cancelRemove = () => {
        confirmationModal(null);
    }
    // let blogimg = blog.blogImg.includes('https:') ? blog.blogImg : 'https://yashdigitalcatdev.blob.core.windows.net/blogs/' + blog.blogImg;
    let cardUrl = DEFAULT_LAYOUT_PATH + '/blog/' + blog.blogId;
    let user_role = StorageService.getUserData() ? StorageService.getUserData().user_role : null;
    if (
        blog.status === statusList.pending &&
        (user_role === roles.admin ||
            user_role === roles.owner)
    ) {
        cardUrl = DEFAULT_LAYOUT_PATH + '/1/' + blog.blogId;
    }

    return (
        <React.Fragment>
            <Card title={blog.title} className="blogCard shadow overflow-hidden" >
                <Link to={cardUrl} className="text-decoration-none">
                    <div style={{ width: '100%', height: 235, background: `url(${JSON.stringify(blog.thumbnailImg) || blog_thumb_placeholder}) no-repeat center center`, backgroundSize: 'cover' }} className="position-relative overflow-hidden">
                        {
                            StorageService.getUserData() &&
                            (blog.authorEmail === StorageService.getUserData().user_email || StorageService.getUserRole() === roles.admin)
                            &&

                            <Dropdown isOpen={dropdownOpen} toggle={toggle} onClick={(e) => { e.preventDefault(); }}>
                                <DropdownToggle tag="div" className="tile-action">
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                </DropdownToggle>
                                <DropdownMenu right >
                                    <DropdownItem onClick={(e) => { e.preventDefault(); history.push(`${DEFAULT_LAYOUT_PATH}/add-blog?edit=true`, { blogData: blog }) }} className="small">Edit</DropdownItem>
                                    <DropdownItem onClick={(e) => { e.preventDefault(); confirmationModal(blog.blogId) }} className="small">Delete</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        }

                        <span className="badge badge-light border-primary" style={{
                            borderLeft: '4px solid',
                            position: 'absolute',
                            top: 30,
                            borderRadius: '0 .2rem .2rem 0',
                            padding: '4px 14px'
                        }}>{blog.technology}</span>
                    </div>
                    <CardBody>
                        <div>{Truncate(blog.title, 39)}</div>
                        <div className="small text-muted mt-1">
                            <div>{blog.author}</div>
                            <div>{dateFormat(blog.Timestamp, 'mmmm dS, yyyy')}</div>
                        </div>

                    </CardBody>
                </Link>
            </Card>
            <Modal isOpen={modal} toggle={confirmationModal} backdrop="static">
                <ModalBody className="py-5 text-center">
                    <ShowIf condition={removing}>
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            background: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}><Spinner isLoading={removing} size="sm" /></div>
                    </ShowIf>
                    Do you want to delete the Blog?
                    <div className="mt-3">
                        <Button color="danger" onClick={letDeleteTile} size="sm" className="mx-2 px-4">Yes</Button>
                        <Button color="primary" onClick={cancelRemove} size="sm" className="mx-2 px-4">Cancel</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
}

export default Index;