import * as CryptoJS from "crypto-js";
import { localUserDataKey } from "config";

class StorageService {
  static iv = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SECRET_KEY);
  static Key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SECRET_KEY);
  static storage = window.localStorage;
  /**
   * Setting data to the storage in encrypted form
   * @param {String} key
   * @param {Any} data
   */
  static setItem(key, data) {
    // let encrypted = null;
    if (!key) {
      throw new Error("key is required");
    }
    if (typeof key !== "string") {
      throw new Error("key must be a string");
    }
    if (data) {
      // data = JSON.stringify(data);
      // encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), this.Key,
      //     {
      //         keySize: 128 / 16,
      //         iv: this.iv,
      //         mode: CryptoJS.mode.CBC,
      //         padding: CryptoJS.pad.Pkcs7
      //     });
      this.storage.setItem(key, data);
    } else {
      throw new Error("Data is required");
    }
  }

  /**
   * get data from storage
   * @param {String} key
   */
  static getItem(key) {
    if (!key) {
      throw new Error("key is required");
    }
    if (typeof key !== "string") {
      throw new Error("key must be a string");
    }
    let data = this.storage.getItem(key);

    if (data && data !== "undefined" && data != null) {
      let decrypted = CryptoJS.AES.decrypt(data, this.Key, {
        keySize: 128 / 16,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    }
    return null;
  }

  static removeItem(key) {
    this.storage.removeItem(key);
  }

  static setUserData(data) {
    this.setItem(localUserDataKey, data);
  }

  static getUserRole() {
    const userData = this.getUserData();
    if (!userData) {
      return null;
    }
    return userData.user_role;
  }
  static getOrganizationId() {
    const storedData = this.getItem(localUserDataKey);
    if (!storedData || storedData === null) {
      throw new Error(`${localUserDataKey} is not found`);
    }
    return storedData.organizationId;
  }
  static getUserData() {
    return this.getItem(localUserDataKey);
  }
  static removeUserData() {
    return this.removeItem(localUserDataKey);
  }
}

export default StorageService;
