/**
 * Layout is used for all views
 */

import { Footer, Header, Sidebar, SubscribeBar } from "components";
import queryString from 'query-string';
import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import StorageService from 'services/storage.services';

const DefaultLayout = ({ children }) => {
    let history = useHistory();
    let location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    const setUserData = async () => {
        let userData = StorageService.getUserData();
        if (!userData) {
            if (location.search) {
                const userData = queryString.parse(location.search);
                await StorageService.setUserData(userData.user.replace(/ /g, "+"));
                setIsLoggedIn(true);
                history.replace(location.pathname);
            }
        } else {
            setIsLoggedIn(true);
        }
    }
    React.useEffect(() => {
        setUserData();
    });
    return (
        <section style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
        }}>
            <SubscribeBar isLoggedIn={isLoggedIn} />

            <Header isLoggedIn={isLoggedIn} />
            <Sidebar />
            <main className="d-flex flex-row flex-grow-1 py-3">
                {children}
            </main>
            <Footer />
        </section>
    );
};

export default DefaultLayout;
