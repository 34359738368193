import { chat } from "assets/images";
import ReactWebChat, {
  createDirectLine,
  createStore,
} from "botframework-webchat";
import React from "react";
import { ShowIf } from "../index";
import { chatbotUrl } from "config";

const store = createStore();

const Index = () => {
  // const directLine = React.useMemo(() => createDirectLine({ token }), []);

  const [token, setToken] = React.useState(null);
  const [shouldDisplayPopUp, setShouldDisplayPopUp] = React.useState(false);

  const getToken = async () => {
    try {
      const response = await fetch(chatbotUrl, { method: "POST" });
      if (response) {
        const { token } = await response.json();
        setToken(token);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getToken();
  }, []);

  const togglePopup = () => {
    setShouldDisplayPopUp(!shouldDisplayPopUp);
  };

  return (
    <React.Fragment>
      <ShowIf condition={!shouldDisplayPopUp}>
        <button
          onClick={togglePopup}
          className="btn btn-primary border-0 shadow-lg"
          type="button"
          style={{
            position: "fixed",
            right: 15,
            bottom: 15,
            borderRadius: "100%",
            border: 0,
            zIndex: 2,
            height: 75,
            width: 75,
            background: "#fff",
          }}
        >
          <img src={chat} alt="" style={{ width: 50, height: 50 }} />
        </button>
      </ShowIf>
      <ShowIf condition={shouldDisplayPopUp}>
        <div
          className="shadow"
          style={{
            position: "fixed",
            width: 350,
            height: 450,
            bottom: 0,
            right: 0,
            zIndex: 1050,
            paddingBottom: 50,
          }}
        >
          <div
            onClick={togglePopup}
            className="bg-primary mb-0 px-3 py-2 rounded-top text-light d-flex justify-content-between align-items-center"
          >
            Chat
            <button
              className="btn text-light"
              style={{ background: "transparent" }}
              onClick={togglePopup}
            >
              <svg
                style={{ fill: "currentColor" }}
                height="15px"
                viewBox="0 0 329.26933 329"
                width="15px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
              </svg>
            </button>
          </div>
          <ReactWebChat
            directLine={createDirectLine({ token })}
            store={store}
            styleOptions={{}}
          />
        </div>
      </ShowIf>
    </React.Fragment>
  );
};

export default Index;
