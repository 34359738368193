import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CN from 'classnames';
import { Tile, Placeholder } from 'components';
import { apiUrl, defaultHeaders } from 'config';
import { ThemeContext } from 'context';
import _ from 'lodash';
import React from 'react';
import styles from "./styles.module.scss";

const Sidebar = (props) => {
    const context = React.useContext(ThemeContext);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataList, setDataList] = React.useState([]);
    const mountedRef = React.useRef();

    const rootClasses = CN({
        [styles.sidebar]: true,
        [styles.sidebarOpen]: isOpen,
        [styles.sidebarDark]: context.theme === 'dark',
        'shadow': context.theme === 'dark'
    });

    const buttonClasses = CN('btn', 'btn-primary', 'shadow btn-lg', {
        [styles['btn-sidebar']]: true,
        [styles.btnHidden]: isOpen || isLoading

    })
    const fetchUpcomingList = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(`${apiUrl}/catalogcategory?type=upcoming`, {
                headers: defaultHeaders
            });
            if (response.status === 401) {
                return;
                // notify.show(data.message, "error");
            }
            if (response.status === 200) {
                const data = await response.json();
                if (mountedRef.current) {
                    setDataList(data);
                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    React.useEffect(() => {
        mountedRef.current = true;
        fetchUpcomingList();
        return () => {
            mountedRef.current = false;
        }
    }, [])
    const toggleHandler = (e) => {
        setIsOpen(!isOpen);
        e.target.blur();
    }
    return (
        <React.Fragment>

            <button onClick={toggleHandler} style={{ fontSize: 14 }} className={buttonClasses}>Upcoming</button>

            <aside className={rootClasses}>
                <div className={styles.sidebarHeader}>
                    <h6 className="mb-0">Upcoming</h6>
                    <div className={styles['close-btn']} onClick={toggleHandler}><FontAwesomeIcon icon={faTimes} /></div>
                </div>
                <div className={styles.sidebarBody}>
                    {
                        dataList &&
                        dataList.map(row => {
                            return <Tile dataSrc={row} key={row.catalogId} className="shadow-none border mb-3" />
                        })
                    }
                    <Placeholder shouldShow={_.isEmpty(dataList) && !isLoading} />
                </div>
            </aside>
            {
                isOpen &&

                <div onClick={toggleHandler} className={styles.overLay}></div>
            }
        </React.Fragment>
    );
};

export default Sidebar;
