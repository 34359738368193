import React from 'react';
import { Link } from "react-router-dom";

const PageNotFound = (props) => {
    return (
        <div className="text-center p-5">
            <h1>
                Oops!</h1>
            <h2>
                404 Not Found</h2>
            <div className="error-details">
                Sorry, an error has occured, Requested page not found!</div>
            <div className="mt-2">
                <Link to="/" className="btn btn-primary px-4">
                    Take me to the Catalog </Link>
            </div>
        </div>
    )
}

export default PageNotFound;