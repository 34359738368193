import { profile } from 'assets/images';
import classnames from 'classnames';
import React from 'react';
import { useHistory, NavLink, useRouteMatch } from "react-router-dom";
import { Button, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { fetchUserProfile } from 'services/httpServices';
import StorageService from 'services/storage.services';
import styles from './styles.scss';


const Index = () => {
    const [userPhoto, setUserPhoto] = React.useState(null);
    const [userData, setUserData] = React.useState(null);
    const history = useHistory();
    const routeMatch = useRouteMatch();

    const fetchDetails = React.useCallback(async (userId) => {
        try {
            const userProfileData = await fetchUserProfile(userId);
            if (userProfileData || userProfileData !== 'Resource Not Found' || userProfileData !== 'Invalid Credential found') {
                const { data } = userProfileData;
                let photo = data.photo ? 'data:image/webp;base64,' + data.photo : profile;
                setUserPhoto(photo);
            }
        } catch (error) {
            throw error;
        }
    }, []);

    React.useEffect(() => {
        const userData = StorageService.getUserData();

        if (userData) {
            setUserData(userData);
            fetchDetails(userData.user_email);
        }
    }, [fetchDetails])

    const getLogout = () => {
        StorageService.removeUserData();
        history.push('/home');
    }
    const cx = classnames.bind(styles);
    const buttonClasses = cx(
        'bg-transparent border-0 font-weight-bolder shadow-none text-decoration-none',
        {
            userDropDown: true
        }
    )
    let template = null;


    if (userData) {
        template = <UncontrolledDropdown>
            <DropdownToggle tag={Button} className={buttonClasses} caret style={{ cursor: "pointer", color: 'inherit' }}>
                <span className="mr-2 rounded-circle border align-middle d-inline-block" style={{ width: 25, height: 25, overflow: 'hidden' }}>
                    <img src={userPhoto} alt="" width="25" />
                </span>
                <span className="align-middle d-inline-block text-truncate" style={{ maxWidth: 150 }}>{userData.user_name}</span>
            </DropdownToggle>
            <DropdownMenu modifiers={{
                setWidth: {
                    enabled: true,
                    fn: (data) => {
                        return {
                            ...data,
                            styles: {
                                ...data.styles,
                                width: 250
                            },
                        };
                    }
                }
            }} right>
                <DropdownItem header tag="div">
                    <div className="text-truncate" title={userData.user_email}>{userData.user_email}</div>
                    <span className="small text-capitalize">{userData.user_role || 'user'}</span>
                </DropdownItem>
                <DropdownItem divider></DropdownItem>

                <NavLink to={`${routeMatch.path}/my-blogs`} className="py-2 dropdown-item text-capitalize">
                    <svg className="mr-2" width="22" viewBox="0 0 431.66 431"><path d="M75.375 370.96c2.797 1.996 6.422 2.418 9.602 1.117l110.168-45.094a10 10 0 0 0 4.121-3.133L360.38 115.82a63 63 0 0 0 12.637-46.898 62.98 62.98 0 0 0-24.48-41.949l-18.53-14c-27.58-20.683-66.647-15.457-87.82 11.75L80.27 234.238a10.05 10.05 0 0 0-2.07 5.543l-7 122.47a10 10 0 0 0 4.176 8.71zm21.688-110.754l73.586 55.188-78.578 32.16zm92.07 44.05l-87.176-65.38 131.488-170.14 86.965 66.02zm68.88-267.3c14.453-18.566 41.113-22.137 59.938-8.02l18.53 14a42.99 42.99 0 0 1 16.711 28.633 43 43 0 0 1-8.629 32.012l-11.906 15.37-86.98-66.03zm163.647 374.42H10a10 10 0 1 0 0 20h411.66a10 10 0 1 0 0-20zm0 0" /></svg>
                    Your Blogs
                </NavLink>
                <DropdownItem className="py-2" onClick={getLogout}>
                    <svg className="mr-2" width="22" viewBox="0 -10 490.667 490"><path d="M474.668 251H165.332a16.01 16.01 0 0 1-16-16 16.01 16.01 0 0 1 16-16h309.336a16.01 16.01 0 0 1 16 16 16.01 16.01 0 0 1-16 16zm0 0" /><path d="M250.668 336.332c-4.098 0-8.2-1.555-11.3-4.7L154.026 246.3c-6.25-6.254-6.25-16.387 0-22.637l85.332-85.332c6.25-6.25 16.383-6.25 22.637 0s6.25 16.383 0 22.633L187.97 235l74.027 74.027c6.25 6.25 6.25 16.383 0 22.633a16.02 16.02 0 0 1-11.328 4.672zm-16 133.336C105.28 469.668 0 364.387 0 235S105.28.332 234.668.332c97.086 0 182.805 58.4 218.4 148.824 3.242 8.2-.812 17.492-9.023 20.754-8.215 3.203-17.496-.8-20.758-9.043-30.742-78.082-104.8-128.535-188.63-128.535C122.922 32.332 32 123.258 32 235s90.922 202.668 202.668 202.668c83.84 0 157.887-50.453 188.63-128.512 3.242-8.258 12.523-12.246 20.758-9.047 8.2 3.242 12.266 12.543 9.023 20.758-35.605 90.4-121.324 148.8-218.4 148.8zm0 0" /></svg>
                Log Out
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>;
    }
    return template;
}

export default Index;
