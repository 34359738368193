import "assets/scss/bootstrap.scss";
import { ScrollToTop, ChatBot } from "components";
import { ThemeContext } from "context";
import SidebarContextProvider from "context/sidebar.context";

import React from "react";
import Notifications from "react-notify-toast";
import { Router } from "react-router-dom";
import "react-widgets/lib/scss/react-widgets.scss";
import StoreProvider from "redux/store";
import RouteSwitcher from "./suspense";
import "suneditor/dist/css/suneditor.min.css";
import NotificationContext from "components/Notifications/notification.context";
import { useNotificationContextValues } from "components/Notifications/notification.hooks";

import MyBlogContext from "views/blog/my-blog/myBlog.context";
import { useMyBlogContextValues } from "views/blog/my-blog/myBlog.hooks";

const App = ({ browserHistory }) => {
  let [isTheme, setTheme] = React.useState("light");
  const notificationContextValues = useNotificationContextValues();
  const myBlogContextValues = useMyBlogContextValues();
  const toggleTheme = () => {
    const theme = isTheme === "dark" ? "light" : "dark";
    window.localStorage.setItem("theme", theme);
    document.documentElement.classList.add("theme-transition");
    document.documentElement.setAttribute("data-theme", theme);
    setTheme(theme);
    window.setTimeout(function () {
      document.documentElement.classList.remove("theme-transition");
    }, 1000);
  };

  React.useEffect(() => {
    const currentTheme = window.localStorage.getItem("theme");
    if (currentTheme) {
      window.localStorage.setItem("theme", currentTheme);
      document.documentElement.setAttribute("data-theme", currentTheme);
      setTheme(currentTheme);
    }
  }, []);

  return (
    <StoreProvider>
      <ThemeContext.Provider value={{ toggleTheme, theme: isTheme }}>
        <MyBlogContext.Provider value={myBlogContextValues}>
          <NotificationContext.Provider value={notificationContextValues}>
            <SidebarContextProvider>
              <ChatBot />
              <Router
                history={browserHistory}
                basename={process.env.REACT_APP_BASENAME || "/"}
              >
                <ScrollToTop>
                  <RouteSwitcher />
                </ScrollToTop>
              </Router>
              <Notifications />
            </SidebarContextProvider>
          </NotificationContext.Provider>
        </MyBlogContext.Provider>
      </ThemeContext.Provider>
    </StoreProvider>
  );
};

export default App;
