/* eslint-disable react-hooks/exhaustive-deps */
import classname from 'classnames';
import { Placeholder } from 'components';
import ShowIf from 'components/ShowIf';
import { roles } from 'config';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import StorageService from 'services/storage.services';
import { useNotificationContext } from './notification.hooks';


const Index = (props) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const routeHistory = useHistory();
    const routeMatch = useRouteMatch();
    const toggle = () => setDropdownOpen(dropdownOpen ? false : true);
    const { fetchNotifications, count, notificationList, isDataAvailable } = useNotificationContext();
    React.useEffect(() => {
        fetchNotifications();
    }, [])
    const onClickHandler = (blog) => {
        const baseIdforurl = blog.authorEmail !== StorageService.getUserData().user_email ? '/1/' : '/blog/';
        return routeHistory.push(routeMatch.path + baseIdforurl + blog.blogId);
    }

    const buttonClasses = classname('shadow-none btn-link  ml-3', [props.className]);
    return (
        <React.Fragment>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle tag={Button} className={buttonClasses} style={{ background: 'transparent', border: 'none' }}>
                    <span className="position-relative">
                        <svg width="18" fill="currentColor" viewBox="0 0 512.001 512.001">
                            <path d="M504.45 365.9c-60.22-60.237-69.282-90.462-69.282-186.7C435.168 80.23 354.955 0 256.002 0S76.836 80.23 76.836 179.2c0 51.89-1.382 71.262-8.525 95.044-8.883 29.628-27.12 57.993-60.766 91.657-16.12 16.128-4.7 43.7 18.1 43.7H167.7l-1.29 12.8c0 49.485 40.107 89.6 89.583 89.6s89.583-40.115 89.583-89.6l-1.29-12.8H486.35c22.8 0 34.228-27.57 18.1-43.7zM256 486.4c-35.337 0-63.99-28.663-63.99-64l1.29-12.8h125.39l1.306 12.8c-.001 35.337-28.656 64-63.993 64zM25.653 384c76.783-76.8 76.783-128 76.783-204.8 0-84.82 68.753-153.6 153.566-153.6s153.566 68.78 153.574 153.6c0 76.8 0 128 76.783 204.8H25.653z" />
                        </svg>
                        {

                            isDataAvailable &&
                            <Badge className="text-light" color="warning" pill style={{
                                position: 'absolute',
                                right: -15, top: -8
                            }}>
                                {count}
                            </Badge>
                        }
                        <span className="sr-only">unread notification</span>
                    </span>

                </DropdownToggle>

                <DropdownMenu modifiers={{
                    setWidth: {
                        enabled: true,
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    width: 370
                                },
                            };
                        }
                    },
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    overflow: 'auto',
                                    maxHeight: '300px',
                                },
                            };
                        },
                    },
                }} right>
                    <Placeholder shouldshow={!count} size="sm" className="font-weight-normal py-4" />
                    {
                        isDataAvailable &&
                        notificationList.map(blog => {
                            return <DropdownItem onClick={(e) => { onClickHandler(blog) }} className="py-2 dropdown-item text-wrap" key={blog.blogId}>

                                <ShowIf condition={blog.authorEmail === StorageService.getUserData().user_email}>
                                    <span>
                                        <b className="text-capitalize">{blog.title}</b> blog request has been <b className="text-capitalize">{blog.status}</b> by <b className="text-capitalize">{blog.adminName}</b>
                                    </span>
                                </ShowIf>


                                <ShowIf condition={
                                    (StorageService.getUserRole() === roles.admin || StorageService.getUserRole() === roles.owner) &&
                                    blog.authorEmail !== StorageService.getUserData().user_email}>
                                    <span>
                                        <b className="text-capitalize">{blog.title}</b> blog request has been raised by <b className="text-capitalize">{blog.author}</b>
                                    </span>
                                </ShowIf>


                            </DropdownItem>
                        })
                    }


                </DropdownMenu>
            </Dropdown>

        </React.Fragment>
    );
}

export default Index;
