import React from "react";
export const SidebarContext = React.createContext();

class SidebarContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSideBarOpen: false,
            toggleSlidebar: this.toggleSlidebar
        }
    }
    toggleSlidebar = () => {
        this.setState(state => ({
            isSideBarOpen: state.isSideBarOpen === true
                ? false
                : true
        }))
    }
    render() {
        return (
            <SidebarContext.Provider value={this.state}>
                {this.props.children}
            </SidebarContext.Provider>
        )
    }
}
export default SidebarContextProvider;