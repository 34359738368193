/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect } from "react";
import { notify } from "react-notify-toast";
import { NavLink, withRouter } from "react-router-dom";
import {
  Collapse,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from "reactstrap";
import { GetNavMasterData } from "services/httpServices";

const Navigation = (props) => {
  const [techDropdownOpen, setTechDropdownOpen] = React.useState(false);
  const toggleTechDropDown = () =>
    setTechDropdownOpen((prevState) => !prevState);

  const [industryDropdownOpen, setIndustryDropdownOpen] = React.useState(false);
  const toggleIndustryDropDown = () =>
    setIndustryDropdownOpen((prevState) => !prevState);

  const [solutionDropdownOpen, setSolutionDropdownOpen] = React.useState(false);
  const toggleSolutionDropDown = () =>
    setSolutionDropdownOpen((prevState) => !prevState);

  const [technologiesList, setTechnologiesList] = React.useState([]);
  const [solutionList, setSolutionList] = React.useState([]);
  const [industriesList, setIndustriesList] = React.useState([]);

  useEffect(() => {
    asyncFetch();
  }, []);
  const asyncFetch = async () => {
    try {
      const response = await GetNavMasterData();
      if (response.status === 401) {
        const data = await response.json();
        notify.show(data.message, "error");
        return;
      }
      if (response.status === 200) {
        const data = await response.json();
        setTechnologiesList(data.technologiesList);
        setSolutionList(data.solutionList);
        setIndustriesList(data.industriesList);
      }
    } catch (error) {
      notify.show("Something went wrong", "error");
      throw error;
    }
  };

  return (
    <Collapse navbar tag="nav">
      <Nav navbar>
        <NavItem className="mx-2">
          <NavLink
            activeClassName="active"
            exact
            className="nav-link"
            to={`${props.match.path}/catalogs`}
          >
            Catalog
          </NavLink>
        </NavItem>
        <Dropdown
          setActiveFromChild
          isOpen={industryDropdownOpen}
          toggle={toggleIndustryDropDown}
        >
          <DropdownToggle
            tag="a"
            className="nav-link mx-2"
            caret
            style={{ cursor: "pointer" }}
          >
            Industries
          </DropdownToggle>
          <DropdownMenu>
            {industriesList.map((industry) => {
              const _industry = encodeURIComponent(industry);
              return (
                <NavLink
                  onClick={toggleIndustryDropDown}
                  key={industry}
                  to={`${props.match.path}/catalog-list/industry/${_industry}`}
                  className="dropdown-item text-capitalize"
                >
                  {industry}
                </NavLink>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          setActiveFromChild
          isOpen={techDropdownOpen}
          toggle={toggleTechDropDown}
        >
          <DropdownToggle
            tag="a"
            className="nav-link mx-2"
            caret
            style={{ cursor: "pointer" }}
          >
            Technologies
          </DropdownToggle>
          <DropdownMenu>
            {technologiesList.map((techs) => {
              const _techs = encodeURIComponent(techs);
              return (
                <NavLink
                  onClick={toggleTechDropDown}
                  key={techs}
                  to={`${props.match.path}/catalog-list/technology/${_techs}`}
                  className="dropdown-item text-capitalize"
                >
                  {techs}
                </NavLink>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          setActiveFromChild
          isOpen={solutionDropdownOpen}
          toggle={toggleSolutionDropDown}
        >
          <DropdownToggle
            tag="a"
            className="nav-link mx-2"
            caret
            style={{ cursor: "pointer" }}
          >
            Solutions
          </DropdownToggle>
          <DropdownMenu>
            {solutionList.map((solution) => {
              const _solution = encodeURIComponent(solution); //solution.replace(/\//g, "%2F")
              return (
                <NavLink
                  onClick={toggleSolutionDropDown}
                  key={solution}
                  to={`${props.match.path}/catalog-list/solution/${_solution}`}
                  className="dropdown-item text-capitalize"
                >
                  {solution === "Project" && "Projects"}
                  {solution !== "Project" && solution}
                </NavLink>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        <NavItem className="mx-2">
          <NavLink
            activeClassName="active"
            exact
            className="nav-link"
            to={`${props.match.path}/blog-list`}
          >
            Blogs
          </NavLink>
        </NavItem>
      </Nav>
      {props.children}
    </Collapse>
  );
};

export default withRouter(Navigation);
