import { apiUrl, defaultHeaders } from 'config';
export const constants = {
    FETCH_TAGSANDINDUSTRIES: 'FETCH_TAGSANDINDUSTRIES',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_FAILED: 'FETCH_FAILED'
}

export const actions = {
    letFetchTags() {
        return async dispatch => {
            try {
                dispatch({ type: constants.FETCH_TAGSANDINDUSTRIES });
                const response = await fetch(`${apiUrl}/listindustriesandtags`, {
                    headers: defaultHeaders
                });
                if (response.status === 500) {
                    console.log("something wrong")
                }
                const contentType = response.headers.get("content-type");
                if (response.status === 200) {
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        const result = await response.json();
                        dispatch(actions.fetchSuccess(result));
                    } else {
                        dispatch(actions.fetchFailed());
                    }
                }
            } catch (error) {
                dispatch(actions.fetchFailed());
                throw error;
            }

        }
    },
    fetchSuccess(payload) {
        return { type: constants.FETCH_SUCCESS, payload }
    },
    fetchFailed() {
        return { type: constants.FETCH_FAILED }
    }
}

const initialState = {
    isloading: false,
    dataList: []
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.FETCH_TAGSANDINDUSTRIES:
            return {
                ...state,
                isloading: true
            };
        case constants.FETCH_SUCCESS:
            return {
                ...state,
                isloading: false,
                dataList: action.payload
            };
        case constants.FETCH_FAILED:
            return {
                ...state,
                isloading: false
            };
        default:
            return state;
    }
}