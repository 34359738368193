import { YashLogo } from "assets/images";
import { Navigation, Notification, Search as GlobalSearch, ThemedButton, UserMenu } from 'components';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { GetLogin } from 'services/httpServices';
import StorageService from 'services/storage.services';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false
        }
    }

    setUserData = async () => {
        let userData = StorageService.getUserData();
        if (!userData) {
            this.setState({
                isLoggedIn: false
            })
        } else {
            this.setState({
                isLoggedIn: true
            })
        }

    }
    getLogin = () => {
        const location = window.location.href;
        GetLogin(location);
        // window.location.href = `${authApiURL}/login/auth?state=${location}`;
    };
    render () {
        const { isLoggedIn } = this.props;
        let userAuthButton = <Button color="primary" className="shadow-none ml-3 border-0" onClick={this.getLogin}>
            <span className="mr-1">
                <svg width="18" fill="currentColor" viewBox="0 -10 490.667 490"><path d="M325.332 251H16a16.01 16.01 0 0 1-16-16 16.01 16.01 0 0 1 16-16h309.332a16.01 16.01 0 0 1 16 16 16.01 16.01 0 0 1-16 16zm0 0" /><path d="M240 336.332c-4.098 0-8.2-1.555-11.3-4.7-6.25-6.25-6.25-16.383 0-22.637l74.027-74.023-74.027-74.027c-6.25-6.25-6.25-16.387 0-22.637s16.387-6.25 22.637 0l85.332 85.336c6.25 6.25 6.25 16.383 0 22.633l-85.332 85.332a15.88 15.88 0 0 1-11.328 4.715zm16 133.336c-97.1 0-182.805-58.4-218.4-148.824-3.242-8.2.8-17.492 9.023-20.734 8.2-3.2 17.516.8 20.758 9.047 30.732 78.058 104.78 128.51 188.62 128.51 111.742 0 202.668-90.926 202.668-202.668S367.742 32.332 256 32.332c-83.84 0-157.887 50.453-188.63 128.512-3.266 8.258-12.566 12.246-20.758 9.047-8.215-3.242-12.266-12.543-9.023-20.734C73.195 58.742 158.9.332 256 .332 385.387.332 490.668 105.613 490.668 235S385.387 469.668 256 469.668zm0 0" /></svg>
            </span>Login</Button>
        if (isLoggedIn) {
            userAuthButton = <React.Fragment>
                <Notification />
                <UserMenu />
            </React.Fragment>
        }
        return (
            <header style={{
                minHeight: 66
            }}>
                <nav className="navbar navbar-expand-xl fixed-top">
                    <Container>
                        <Link to="/home" className="navbar-brand">
                            <img src={YashLogo} alt="Yash" width="70" />
                        </Link>
                        <Navigation />
                        <GlobalSearch className="flex-grow-1" />

                        <ThemedButton className="ml-3 border-0" />
                        {userAuthButton}
                    </Container>
                </nav>
            </header>
        );
    }
};

export default withRouter(Header);