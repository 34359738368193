
import React, { Component } from 'react';
import Disqus from 'disqus-react';
class Discussion extends Component {
    render() {
        const disqusShortname = 'https-yashgallary-z20-web-core-windows-net';
        const disqusConfig = {
            url: window.location.href,
            identifier: this.props.article.id,
            title: this.props.article.title,
        };
        return (
            <div className="article">
                <h6>{this.props.article.title}</h6>
                <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
            </div>
        );
    }
}

export default Discussion;
