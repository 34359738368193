import React from 'react';
import { useHistory } from "react-router-dom";
import { Button } from 'reactstrap';
import { DEFAULT_LAYOUT_PATH } from 'routes';
import { GetLogin } from 'services/httpServices';
import StorageService from 'services/storage.services';


const Index = ({ text }) => {
    let history = useHistory();

    const goToWriteBlog = async () => {
        try {
            const userData = await StorageService.getUserData();
            if (!userData) return GetLogin(window.location.origin + DEFAULT_LAYOUT_PATH + '/add-blog');
            history.push(DEFAULT_LAYOUT_PATH + '/add-blog');
        } catch (error) {
            throw error;
        }


    }
    return <Button color="primary" onClick={goToWriteBlog}>{text || 'Write Blog'}</Button>;
};

export default Index;
// <Button className="btn btn-primary" to={DEFAULT_LAYOUT_PATH + '/add-blog'}>Write Blog</Button>