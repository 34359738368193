import React from 'react';
import iconList from "./iconlist";
const defaultProps = {
    tag: 'span'
};
const Icon = (props) => {
    const icon = iconList[props.iconName];
    const style = {
        fontFamily: 'icomoon',
        speak: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        ...props.style
    }

    const { tag: Tag } = props;
    return (
        <Tag style={style} className={props.className} dangerouslySetInnerHTML={{ __html: icon }}></Tag>
    );
};
Icon.defaultProps = defaultProps;
export default Icon;
