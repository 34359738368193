
import axios from 'axios';
import { apiUrl, defaultHeaders } from 'config';
import StorageService from 'services/storage.services';
class BlogServices {
    static async fetchBlogList (type = 'latest', value) {
        let query = value ? `?type=${type}&value=${value}` : `?type=${type}`
        return await axios.get(`${apiUrl}/bloglist${query}`, { headers: defaultHeaders });
    }

    static async addBlog (formData) {
        return await axios.post(apiUrl + '/addblog', formData, {
            headers: {
                ...defaultHeaders
            }
        });
    }

    static async deleteBlog (blogId) {
        return await axios.post(`${apiUrl}/deleteblog`, { blogId }, {
            headers: {
                ...defaultHeaders
            }
        });
    }

    static async getBlogDetails (blogId) {
        return await axios.get(`${apiUrl}/blogdetails?id=${blogId}`, {
            headers: {
                ...defaultHeaders
            }
        });
    }

    static async fetchUserBlogs (type = 'all') {
        try {
            const userData = await StorageService.getUserData();
            if (!userData) {
                return await { data: { error: 401, msg: 'Unauthorized' } }
            }

            return await axios.get(`${apiUrl}/blogapprovals?user=${userData.user_email}&type=${type}`, {
                headers: {
                    ...defaultHeaders
                }
            });
        } catch (error) {
            throw error;
        }
    }
}

export default BlogServices;