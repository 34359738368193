import axios from 'axios';
import { ShowIf, Spinner } from 'components';
import { authApiURL, defaultHeaders, statusList } from 'config';
import React from 'react';
import { notify } from "react-notify-toast";
import { useHistory } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';
import { DEFAULT_LAYOUT_PATH } from 'routes';
import StorageService from 'services/storage.services';
import { useNotificationContext } from '../Notifications/notification.hooks';

const Index = ({ blogId, blogStatus }) => {
    const [commentText, setCommentText] = React.useState(null);
    const [_confirmationModal, setConfirmationModal] = React.useState(false);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [_statusType, setStatusType] = React.useState(null);
    const history = useHistory();
    const { removeNotification } = useNotificationContext();
    const commentTypehandler = (e) => {
        setCommentText(e.target.value);
    }
    const blogAction = async () => {
        setIsProcessing(true)
        const { user_email, api_key } = StorageService.getUserData()
        const requestBody = {
            blogId,
            adminEmail: user_email,
            status: _statusType,
            comment: commentText
        }
        try {
            const data = await axios.post(
                `${authApiURL}/blogaction`,
                requestBody,
                {
                    headers: {
                        ...defaultHeaders,
                        'Ocp-Apim-Subscription-Key': api_key
                    }
                }
            );
            if (data) {
                notify.show(data.data, "success", 5000);
                removeNotification(blogId);
                setTimeout(() => {
                    history.push(`${DEFAULT_LAYOUT_PATH}/my-blogs`)
                }, 5000);
            }
            if (_confirmationModal) {
                letCloseModal();
            };
            setIsProcessing(false)
            // clearAlert();
        } catch (error) {
            throw error;
        }
    }
    const letCloseModal = () => {
        setConfirmationModal(false);
    }
    const showConfirmationModal = (statusType) => {
        setConfirmationModal(true);
        setStatusType(statusType);
    }

    return (
        <div>
            <FormGroup className="mb-3">
                <Label for="exampleText">Comment <sup className="text-danger">*</sup></Label>
                <Input onChange={commentTypehandler} type="textarea" name="comment" rows="5" id="commentField" placeholder="Add a comment to approve or reject the blog" style={{ resize: "none" }} />
            </FormGroup>
            <div className="d-flex justify-content-end align-items-center">
                {!commentText &&
                    <span className="hint-text small mr-3">Please add comment to Approve or Reject.</span>
                }
                <Button onClick={() => { showConfirmationModal(statusList.rejected) }} disabled={!commentText} outline color="primary" style={{ width: 100 }} className="mr-3">Reject</Button>
                <Button onClick={() => { showConfirmationModal(statusList.accepted) }} disabled={!commentText} color="primary" style={{ width: 100 }}>Approve</Button>
            </div>


            <Modal isOpen={_confirmationModal} backdrop="static">
                <ModalBody className="py-5 text-center">
                    <ShowIf condition={isProcessing}>
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            background: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}><Spinner isLoading={isProcessing} size="sm" /></div>
                    </ShowIf>
                    <ShowIf condition={_statusType === statusList.rejected}>
                        Do you want to reject the blog?
                    </ShowIf>
                    <ShowIf condition={_statusType === statusList.accepted}>
                        Do you want to aprove the blog?
                    </ShowIf>
                    <div className="mt-4">
                        <Button color={_statusType === statusList.rejected ? 'danger' : 'success'} size="sm" onClick={blogAction} className="mx-2 px-4" style={{ width: 100 }}>Yes</Button>
                        <Button outline color="primary" onClick={letCloseModal} size="sm" className="mx-2 px-4" style={{ width: 100 }}>Cancel</Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Index;
