import React from 'react';
import MyBlogContext from './myBlog.context';
import BlogServices from 'services/blog.service';
import _ from 'lodash';
const useMyBlogContextValues = () => {
    const [myBlogList, setMyBlogList] = React.useState(null);
    const [isMyBlogLoading, setIsMyBlogLoading] = React.useState(true);
    const [myBlogHasNoData, setMyBlogHasNoData] = React.useState(false);

    const fetchMyBlogs = React.useCallback(async (statusType) => {
        setIsMyBlogLoading(true);
        try {
            const { data } = await BlogServices.fetchUserBlogs(statusType);
            let cleanData = {};
            for (const key in data) {
                if (data[key].length) {
                    // let keyCopy = key.replace('Blogs', '');
                    cleanData[key] = data[key];
                }
            }

            if (_.isEmpty(cleanData)) {
                setIsMyBlogLoading(false);
                return setMyBlogHasNoData(true);
            };
            setMyBlogList(cleanData);

            setIsMyBlogLoading(false);
        } catch (error) {
            throw error;
        }
    }, []);
    return {
        myBlogList,
        isMyBlogLoading,
        myBlogHasNoData,
        fetchMyBlogs
    }
};

const useMyBlogContext = () => {
    const myBlogContext = React.useContext(MyBlogContext);
    if (!myBlogContext) {
        throw new Error('MyBlogContext must be used within the MyBlogContext.Provider');
    }
    return myBlogContext;
}

export { useMyBlogContext, useMyBlogContextValues }